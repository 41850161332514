import React, { useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router';

// store
import { useStoreState, useStoreActions, useStoreDispatch } from '../hooks'
import { componentCategoryLookup } from '../model/codelist';
import { ComponentPrototypeDto, ComponentTypeDto, ComponentCategoryKey, DeviceTypeDto, KeyValuesDto } from '../service/dataContract';

// mui
import { Box, Button, List, ListItem, Tab, Tabs, TextField, Typography, Checkbox, useTheme } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import MaterialTable, { Column } from 'material-table';
import SwipeableViews from 'react-swipeable-views';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// css
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';

import { buildLookup } from '../utils/lookup';
import { validateInput, validateDefined } from '../utils/validationUtils';
import { useValidator, ValidationRules } from '../utils/validations';

// components
import ComponentAdminParams from './ComponentAdminParams';
import { formatDate } from '../utils/datetime';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.background.paper,
        width: 500,
    },
    detailPanel: {
        background: '#EEEE',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'left',
        minHeight: 100,

    },
    paper: {
        flexGrow: 1,
        margin: theme.spacing(0.5, 0, 2, 0.5),
    },
}));

// url params
interface MatchParams {
    id: string;
}

interface Props extends RouteComponentProps<MatchParams> {
}

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

// device service tool
export const ComponentAdmin: React.FC<Props> = props => {

    // styles
    const classes = useStyles();
    const theme = useTheme();

    // store
    const { componentTypes, componentPrototypes, deviceTypes, isLoading } = useStoreState(state => state.component);
    const {
        fetchPrototypes,
        addComponentPrototype, updateComponentPrototype, deleteComponentPrototype,
        addComponentType, updateComponentType, deleteComponentType,
        addDeviceType, updateDeviceType, deleteDeviceType,
    } = useStoreActions(state => state.component);

    // state
    const [tabPageIndex, setTabPageIndex] = React.useState(0);

    // fetch
    useEffect(() => {
        fetchPrototypes();
    }, []);

    // tables columns 
    const componentTypeLookup = buildLookup('id', 'name', componentTypes);

    const prototypeColumns: Column<ComponentPrototypeDto>[] = [
        {
            title: 'Name',
            field: 'name',
            width: '100%',
            validate: rowData => validateInput(rowData.name, 2),
        },
        {
            title: 'Template',
            tooltip:'Determines if component prototype will be treated as parameters template or immutable (shared) parameter list.',
            field: 'isTemplate',
            editable: (_, rowData) => !(rowData?.isAttached?? false),
            width: 150,
            type: 'boolean',
            render: (rowData) => (<Checkbox checked={rowData.isTemplate} />)
        },
        {
            title: 'Used',
            tooltip:'Checks if any component is using this prototype.',
            field: 'isAttached',
            editable: 'never',
            width: 50,
            type: 'boolean',
            render: (rowData) => (<Checkbox checked={rowData.isAttached} color='primary' />)
        },
        {
            title: 'Type',
            field: 'componentType.id',
            lookup: componentTypeLookup,
            validate: rowData => validateDefined(rowData.componentType?.id)
        },
        {
            title: 'Category',
            editable: 'never',
            field: 'componentType.componentCategory',
            lookup: componentCategoryLookup,
        },
        { title: 'Manufactuer', field: 'manufacturer', },
        { title: 'url', field: 'manufacturerUrl', },
        { title: 'Modified by', width: 150, field: 'lastModifiedBy', editable: 'never' },
        { title: 'Modified at', width: 150, field: 'lastModifiedAt', render: dto => formatDate(dto.lastModifiedAt), editable: 'never', defaultSort: 'desc' },
        { title: 'Created by', width: 150, field: 'createdBy', editable: 'never', hidden: true },
        { title: 'Created at', width: 150, field: 'createdAt', type: 'date', editable: 'never', hidden: true },
    ];

    const componentTypeColumns: Column<ComponentTypeDto>[] = [
        {
            title: 'Name',
            field: 'name',
            validate: rowData => validateInput(rowData.name, 2),
        },
        {
            title: 'Category',
            width: 200,
            field: 'componentCategory',
            lookup: componentCategoryLookup,
            validate: rowData => validateDefined(rowData.componentCategory)
        },
        { title: 'Modified by', width: 150, field: 'lastModifiedBy', editable: 'never' },
        { title: 'Modified at', width: 150, field: 'lastModifiedAt', render: dto => formatDate(dto.lastModifiedAt), editable: 'never' },
        { title: 'Created by', width: 150, field: 'createdBy', editable: 'never', hidden: true },
        { title: 'Created at', width: 150, field: 'createdAt', type: 'date', editable: 'never', hidden: true },
    ];

    const deviceTypeColumns: Column<DeviceTypeDto>[] = [
        {
            title: 'Name',
            field: 'name',
            validate: rowData => validateInput(rowData.name, 2),
        },
        {
            title: 'Used',
            tooltip:'Checks if device type is used.',
            field: 'isAttached',
            editable: 'never',
            width: 50,
            type: 'boolean',
            render: (rowData) => (<Checkbox checked={rowData.isAttached} color='primary' />)
        },
        {
            title: 'S/N Prefix',
            field: 'snPrefix',
            validate: rowData => validateInput(rowData.snPrefix, 1),
        },

        { title: 'Modified by', width: 150, field: 'lastModifiedBy', editable: 'never' },
        { title: 'Modified at', width: 150, field: 'lastModifiedAt', render: dto => formatDate(dto.lastModifiedAt), editable: 'never' },
        { title: 'Created by', width: 150, field: 'createdBy', editable: 'never', hidden: true },
        { title: 'Created at', width: 150, field: 'createdAt', type: 'date', editable: 'never', hidden: true },
    ];

    // prototypes edit handlers

    const handlePrototypeCreate = (newData: ComponentPrototypeDto): Promise<void> =>
        new Promise((resolve, reject) => {
            addComponentPrototype({
                prototype: {
                    componentTypeId: newData.componentType?.id ?? '',
                    isTemplate: newData.isTemplate ?? false,
                    name: newData.name,
                    manufacturer: newData.manufacturer,
                    manufacturerUrl: newData.manufacturerUrl,
                }
            });
            resolve();
        });

    const handlePrototypeUpdate = (newData: ComponentPrototypeDto, oldData?: ComponentPrototypeDto): Promise<void> =>
        new Promise((resolve, reject) => {
            updateComponentPrototype({
                prototypeId: oldData?.id ?? '',
                prototype: {
                    componentTypeId: newData.componentType?.id ?? '',
                    name: newData.name,
                    isTemplate: newData.isTemplate ?? false,
                    manufacturer: newData.manufacturer,
                    manufacturerUrl: newData.manufacturerUrl,
                }
            });
            resolve();
        });

    const handlePrototypeParameterUpdate = (data: ComponentPrototypeDto, parameters: KeyValuesDto): Promise<void> =>
        new Promise((resolve, reject) => {
            updateComponentPrototype({
                prototypeId: data?.id ?? '',
                prototype: {
                    payload: parameters
                }
            });
            resolve();
        });


    const handlePrototypeDelete = (oldData: ComponentPrototypeDto): Promise<void> =>
        new Promise((resolve, reject) => {
            deleteComponentPrototype({
                prototypeId: oldData?.id ?? '',
            });
            resolve();
        });

    // component types edit handlers

    const handleComponentTypeCreate = (newData: ComponentTypeDto): Promise<void> =>
        new Promise((resolve, reject) => {
            addComponentType({
                componentType: {
                    name: newData.name ?? '',
                    componentCategory: newData.componentCategory as ComponentCategoryKey,
                }
            });
            resolve();
        });

    const handleComponentTypeUpdate = (newData: ComponentTypeDto, oldData?: ComponentTypeDto): Promise<void> =>
        new Promise((resolve, reject) => {
            updateComponentType({
                typeId: oldData?.id ?? '',
                componentType: {
                    name: newData.name ?? '',
                    componentCategory: newData.componentCategory as ComponentCategoryKey,
                }
            });
            resolve();
        });

    const handleComponentTypeDelete = (oldData: ComponentTypeDto): Promise<void> =>
        new Promise((resolve, reject) => {
            deleteComponentType({
                typeId: oldData?.id ?? '',
            });
            resolve();
        });

    // component types edit handlers

    const handleDeviceTypeCreate = (newData: DeviceTypeDto): Promise<void> =>
        new Promise((resolve, reject) => {
            addDeviceType({
                deviceType: {
                    name: newData.name ?? '',
                    snPrefix: newData.snPrefix,
                }
            });
            resolve();
        });

    const handleDeviceTypeUpdate = (newData: DeviceTypeDto, oldData?: DeviceTypeDto): Promise<any> =>
        new Promise((resolve, reject) => {
            updateDeviceType({
                typeId: oldData?.id ?? '',
                deviceType: {
                    name: newData.name ?? '',
                    snPrefix: newData.snPrefix,
                }
            });
            resolve(true);
        });

    const handleDeviceTypeDelete = (oldData: DeviceTypeDto): Promise<any> =>
        new Promise((resolve, reject) => {
            deleteDeviceType({
                typeId: oldData?.id ?? '',
            });
            resolve(true);
        });



    // tab page handlers

    const handleTabPageChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabPageIndex(newValue);
    };

    const handleTabPageChangeIndex = (index: number) => {
        setTabPageIndex(index);
    };


    return (
        <div>
            <Tabs
                value={tabPageIndex}
                onChange={handleTabPageChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
            >
                <Tab label="Device Component Prototypes" {...a11yProps(0)} />
                <Tab label="Device Component Types" {...a11yProps(1)} />
                <Tab label="Device Types" {...a11yProps(2)} />
            </Tabs>

            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={tabPageIndex}
                onChangeIndex={handleTabPageChangeIndex}
            >
                <TabPanel value={tabPageIndex} index={0} dir={theme.direction}>
                    <MaterialTable
                        title="Component Prototypes"
                        isLoading={isLoading}
                        columns={prototypeColumns}
                        data={componentPrototypes}
                        detailPanel={[
                            ({
                                tooltip: 'Parameters',
                                icon: () => (<ExpandMoreIcon color='primary' />),
                                render: (rowData) => (<ComponentAdminParams prototypeId={rowData.id} isVgn={rowData.isVgn?? false} />)
                            }),
                        ]}
                        options={{
                            filtering: false,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 50],
                            addRowPosition: 'first',
                        }}
                        editable={{
                            onRowAdd: handlePrototypeCreate,
                            onRowUpdate: handlePrototypeUpdate,
                            onRowDelete: handlePrototypeDelete,
                        }}
                        onRowClick={(event, rowData, togglePanel) => { if (togglePanel) togglePanel() }}
                    >
                    </MaterialTable>
                </TabPanel>

                <TabPanel value={tabPageIndex} index={1} dir={theme.direction}>
                    <MaterialTable
                        title="Component Types"
                        isLoading={isLoading}
                        columns={componentTypeColumns}
                        data={componentTypes}
                        options={{
                            filtering: false,
                            pageSize: 10,
                            pageSizeOptions: [5, 10, 20],
                            addRowPosition: 'first',
                        }}
                        editable={{
                            onRowAdd: handleComponentTypeCreate,
                            onRowUpdate: handleComponentTypeUpdate,
                            onRowDelete: handleComponentTypeDelete,
                        }}>
                    </MaterialTable>
                </TabPanel>

                <TabPanel value={tabPageIndex} index={2} dir={theme.direction}>
                    <MaterialTable
                        title="Device Types"
                        isLoading={isLoading}
                        columns={deviceTypeColumns}
                        data={deviceTypes}
                        options={{
                            filtering: false,
                            pageSize: 10,
                            pageSizeOptions: [5, 10, 20],
                            addRowPosition: 'first',
                        }}
                        editable={{
                            onRowAdd: handleDeviceTypeCreate,
                            // temporray disabled
                            //onRowUpdate: handleDeviceTypeUpdate,
                            onRowDelete: handleDeviceTypeDelete,
                        }}>
                    </MaterialTable>
                </TabPanel>

            </SwipeableViews>



        </div>
    );
}

export default ComponentAdmin;

