import React, { useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';

// store
import { useStoreActions, useStoreState } from '../../hooks';

// mui
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// components
import { Accordion, AccordionDetails, AccordionSummary, Badge, Button, Typography } from '@material-ui/core';
import ComponentsCard from '../ComponentCard';
import LicenseListCard from '../software/LicenseListCard';
import ContactCard from './ContactCard';
import DeviceInfoCard from './DeviceInfoCard';
import DeviceToolbar from './DeviceToolbar';
import ChecklistCard from './ChecklistCard';
import { ExpandMore } from '@material-ui/icons';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import routes from '../../routes';

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    accordionDetails: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    card: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        marginBottom: theme.spacing(2),
    },
    cardHeading: {
        flexBasis: '33%',
        flexShrink: 0,
    },

    bottomPadding: {
        height: 300,
    },
    toolbar: {
        marginLeft: 'auto',
    }
}));


// url params
interface MatchParams {
    id: string;
}

interface Props extends RouteComponentProps<MatchParams> {
}

export const DeviceDetail: React.FC<Props> = props => {

    const classes = useStyles();
    const history = useHistory();

    const { isKzv } = useStoreState(state => state.auth);
    const { getDeviceById } = useStoreActions(state => state.device);
    const { deviceDetail } = useStoreState(state => state.device);
    
    useEffect(() => {
        getDeviceById({ deviceId: props.match.params.id });
    }, [getDeviceById]);


    return (

        <div className={classes.root}>

            <DeviceToolbar />

            <div className={classes.card}>
                <DeviceInfoCard readonly={false} />
            </div>

            <div className={classes.card}>
                <ContactCard readonly={false} />
            </div>

            <div className={classes.card}>
                <ComponentsCard />
            </div>

            <div className={classes.card}>
                <ChecklistCard readonly={false}/>
            </div>

            <div className={classes.card}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}>
                        <Typography
                            className={classes.cardHeading}
                            variant='h6'>
                            Software Licenses:
                        </Typography>
                        <Badge
                            color='secondary'
                            variant="standard"
                            badgeContent={deviceDetail?.licenseFiles?.length ?? 0}  >
                            <Button
                                startIcon={<VpnKeyIcon />}
                                color='primary'
                                variant='contained'
                                disabled={!isKzv}
                                onClick={() => { history.push(routes.licenseCreate, deviceDetail?.serialNumber) }}>
                                Create License
                            </Button>
                        </Badge>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <LicenseListCard
                            paging={false}
                            toolbar={false}
                            filtering={false}
                            grouping={false}
                            deviceId={deviceDetail?.id ?? 'none'}
                        />
                    </AccordionDetails>
                </Accordion>
            </div>

            <div className={classes.bottomPadding} />

        </div >
    )
}

export default DeviceDetail;