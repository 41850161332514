import { Action, action, Thunk, thunk } from 'easy-peasy';
import { StoreContext } from '.';
import { Injections } from '../store';
import { DeviceDto, AttachedFileDto, ServiceOperationDto, CreateDeviceDto, CreateContactDto, ContactDto, CreateComponentDto, ComponentDto, DeviceTypeDto, UserDto, DeviceFileBriefDto, LicenseDto, UserRole } from '../service/dataContract';
import { StoreMode, storeValue } from '../utils/storageUtils';
import { Progress } from '../service/httpClient';
import { base64toBlob } from '../utils/fileUtils';
import deviceFileClient from '../service/deviceFileClient';

// device store interface
export interface DeviceModel {

    // loading indnpm startication
    isLoading: boolean,

    // device detail (loaded device model with all details)
    deviceDetail: DeviceDto | undefined,

    // list of database users (device maintainers)
    maintainers: UserDto[],

    // gets the device by id
    getDeviceById: Thunk<DeviceModel, { deviceId: string }, Injections, StoreContext>,

    // gets the new /suggested serial number for given device type (derived from last entry new_sn = sn.numericpart + 1)
    suggestSerialNumber: Thunk<DeviceModel, { deviceType: DeviceTypeDto }, Injections, StoreContext, Promise<{ lastUsedSn: string, suggestedSn: string }>>,

    // fetches the possible maintainers
    fetchMaintainers: Thunk<DeviceModel, void, Injections, StoreContext>,

    // creates a new device
    addDevice: Thunk<DeviceModel, { device: CreateDeviceDto }, Injections, StoreContext, Promise<DeviceDto>>,

    // creates a new device    
    cloneDevice: Thunk<DeviceModel, { deviceId: string, serialNumber: string, name?: string }, Injections, StoreContext, Promise<DeviceDto>>,

    // updates an existing device
    updateDevice: Thunk<DeviceModel, { deviceId: string, device: CreateDeviceDto }, Injections, StoreContext>,

    // deletes (soft) an existing device
    deleteDevice: Thunk<DeviceModel, { deviceId: string }, Injections, StoreContext, Promise<void>>,

    // restores an existing soft-deleted device
    restoreDevice: Thunk<DeviceModel, { deviceId: string, newSerialNumber?: string }, Injections, StoreContext, Promise<void>>,

    // updates device contact info
    updateDeviceContact: Thunk<DeviceModel, { contactId: string, contact: CreateContactDto }, Injections, StoreContext>,


    // creates a new component for given device 
    addDeviceComponent: Thunk<DeviceModel, { deviceId: string, component: CreateComponentDto }, Injections, StoreContext>,

    // updates an existing component prototype 
    updateDeviceComponent: Thunk<DeviceModel, { deviceId: string, componentId: string, component: CreateComponentDto }, Injections, StoreContext>,

    // deletes an existing component
    deleteDeviceComponent: Thunk<DeviceModel, { deviceId: string, componentId: string }, Injections, StoreContext>,


    // ** device file management
    // create (uploads) a new device file
    uploadFile: Thunk<DeviceModel, { deviceId: string, formData: FormData, uploadProgress: Progress }, Injections, StoreContext>,

    // downloads an existing device file
    downloadFile: Thunk<DeviceModel, { deviceId: string, fileId: string, downloadProgress: Progress }, Injections, StoreContext>,

    // delete an existing deviceice file
    deleteFile: Thunk<DeviceModel, { deviceId: string, fileId: string }, Injections, StoreContext>,

    // ** cfg file management
    // downloads an existing device file
    downloadCfgFile: Thunk<DeviceModel, { serialNumber: string, fileId: string, downloadProgress: Progress }, Injections, StoreContext>,

    // deletes an existing device cfg file
    deleteCfgFile: Thunk<DeviceModel, { serialNumber: string, fileId: string }, Injections, StoreContext>,


    // store devices action    
    storeDeviceDetail: Action<DeviceModel, { deviceDetail: DeviceDto }>,
    storeDeviceContact: Action<DeviceModel, { contact: ContactDto }>,

    // stores file
    storeFile: Action<DeviceModel, { mode: StoreMode, fileId?: string, file?: AttachedFileDto }>,

    // stores license file
    storeLicenseFile: Action<DeviceModel, { mode: StoreMode, fileId?: string, file?: LicenseDto }>,

    // stores cfg file
    storeCfgFile: Action<DeviceModel, { mode: StoreMode, fileId?: string, file?: DeviceFileBriefDto }>,

    // stores modified service operation
    storeServiceOperation: Action<DeviceModel, { mode: StoreMode, serviceOpId?: string, serviceOp?: ServiceOperationDto }>,

    // store service file action
    storeServiceFile: Action<DeviceModel, { mode: StoreMode, serviceOpId?: string, fileId?: string, file?: AttachedFileDto }>,

    // stores components
    storeComponent: Action<DeviceModel, { mode: StoreMode, component?: ComponentDto, componentId?: string }>,

    // stores the maintainers (list of app users)
    storeMaintainers: Action<DeviceModel, { maintainers: UserDto[] }>,

    // set loading flag action
    setLoading: Action<DeviceModel, boolean>,
};

const deviceModel: DeviceModel = {

    /* data */

    isLoading: false,
    deviceDetail: undefined,
    maintainers: [],

    /* thunks */

    setLoading: action((store, payload) => { store.isLoading = payload; }),

    // fetch

    getDeviceById: thunk(async (actions, payload, { injections, getStoreActions }) => {

        actions.setLoading(true);
        const { deviceClient } = injections;

        deviceClient.get(payload)
            .then(deviceDetail => {
                actions.storeDeviceDetail({ deviceDetail });
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not find device with id ${payload.deviceId}.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false);
            });
    }),


    fetchMaintainers: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { userClient } = injections;

        userClient.fetch()
            .then(users => {
                var maintainers = users.filter(m => m.role == UserRole.Extern)
                actions.storeMaintainers({ maintainers });
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Unable to load list of maintainers.`, payload: err })
            });
    }),

    suggestSerialNumber: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { deviceClient } = injections;

        return await deviceClient.getLastUsedSerial({ deviceTypeId: payload.deviceType.id })
            .then(lastUsedSn => {

                let lastNumPattern = /\d+(?!\d+)/;
                const matches = lastNumPattern.exec(lastUsedSn);
                const zeroPadLen = 1;
                let suggestedSn = '';

                // parse and get next number
                if (matches) {
                    let lastFoundNumber = Number(matches[0].valueOf());

                    if (payload.deviceType.enforcePrefix)  {
                        suggestedSn =  (lastFoundNumber + 1).toString();
                    }
                    else {
                        suggestedSn = `${lastUsedSn}`.replace(lastNumPattern, (lastFoundNumber + 1).toString().padStart(zeroPadLen, '0'));
                    }
                }
                else {
                    suggestedSn += `1`;
                }

                return Promise.resolve({ lastUsedSn, suggestedSn });
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not get device last used serial number.`, payload: err })
                return Promise.reject({ err: err, decription: 'Unable to get last used device serial.' });
            })
    }),

    // create

    addDevice: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { deviceClient, contactClient } = injections;
        actions.setLoading(true);

        // create  device contact
        return await contactClient.create({ contact: { companyName: '' } })
            .then(async contact => {
                // create device with contact reference
                try {
                    const device = await deviceClient.create({ device: { ...payload.device, contactId: contact.id } });
                    return Promise.resolve(device);
                } catch (err) {
                    return Promise.reject({ err: err, decription: 'Unable to create a new device.' });
                }
            })
            .then(device => {
                getStoreActions().deviceFilter.storeDevice({ mode: 'insert', device: { ...device } });
                getStoreActions().audit.notify({ severity: 'info', message: `New device has been sucessfully created.` });
                return Promise.resolve(device);
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Unable to create a new device ${payload.device.serialNumber}.`, payload: err })
                return Promise.reject(err);
            })
            .finally(() => {
                actions.setLoading(false)
            });
    }),

    updateDevice: thunk(async (actions, payload, { injections, getStoreActions }) => {
        const { deviceClient, contactClient } = injections;
        actions.setLoading(true);

        return await deviceClient.update(payload)
            .then(deviceDetail => {
                actions.storeDeviceDetail({ deviceDetail });
                getStoreActions().deviceFilter.storeDevice({ mode: 'update', device: { ...deviceDetail } });
                getStoreActions().audit.notify({ severity: 'info', message: `Device info has been sucessfully updated.` });
                return Promise.resolve(deviceDetail);
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Unable to update device info ${payload.device.serialNumber}.`, payload: err })
                return Promise.reject(err);
            })
            .finally(() => {
                actions.setLoading(false)
            });
    }),

    deleteDevice: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { deviceClient } = injections;
        actions.setLoading(true);

        await deviceClient.delete({ deviceId: payload.deviceId })
            .then(() => {
                getStoreActions().deviceFilter.storeDevice({ mode: 'delete', deviceId: payload.deviceId });
                getStoreActions().audit.notify({ severity: 'info', message: `Device has been sucessfully deleted.` });
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Unable to delete device ${payload.deviceId}.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false)
            });
    }),

    restoreDevice: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { deviceClient } = injections;
        actions.setLoading(true);

        await deviceClient.restore({ deviceId: payload.deviceId, serialNumber: payload.newSerialNumber })
            .then(() => {
                getStoreActions().audit.notify({ severity: 'info', message: `Device has been sucessfully restored.` });
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Unable to restore device ${payload.deviceId}.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false)
            });
    }),

    cloneDevice: thunk(async (actions, payload, { injections, getStoreActions }) => {
        const { deviceClient } = injections;
        actions.setLoading(true);

        return await deviceClient.clone(payload)
            .then(deviceDetail => {
                actions.storeDeviceDetail({ deviceDetail });
                getStoreActions().deviceFilter.storeDevice({ mode: 'update', device: { ...deviceDetail } });
                getStoreActions().audit.notify({ severity: 'info', message: `Device has been sucessfully cloned.` });
                return Promise.resolve(deviceDetail);
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Unable to clone device SN: ${payload.serialNumber}.`, payload: err })
                return Promise.reject(err);
            })
            .finally(() => {
                actions.setLoading(false)
            });
    }),


    updateDeviceContact: thunk(async (actions, payload, { injections, getStoreActions, getStoreState }) => {

        const { contactClient } = injections;
        const detail = getStoreState().device.deviceDetail;

        if (!detail?.contact) {
            // create a new contact
            await contactClient.create({ contact: payload.contact })
                .then(contact => {
                    getStoreActions().device.updateDevice({ deviceId: detail?.id ?? '', device: { contactId: contact.id } })
                    actions.storeDeviceContact({ contact });
                    getStoreActions().audit.notify({ severity: 'info', message: `Contact info has been sucessfully created.` });
                })
                .catch(err => {
                    getStoreActions().audit.notify({ severity: 'error', message: `Unable to create contact info.`, payload: err })
                })

        }
        else {
            // update an existing contact
            await contactClient.update({ contactId: payload.contactId, contact: payload.contact })
                .then(contact => {
                    actions.storeDeviceContact({ contact });
                    getStoreActions().audit.notify({ severity: 'info', message: `Contact info has been sucessfully updated.` });
                })
                .catch(err => {
                    getStoreActions().audit.notify({ severity: 'error', message: `Unable to update contact info.`, payload: err })
                })
        }
    }),

    // component thunks
    addDeviceComponent: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { componentClient } = injections;
        actions.setLoading(true);

        await componentClient.create(payload)
            .then(response => {
                actions.storeComponent({ mode: 'insert', component: response });
                getStoreActions().audit.notify({ severity: 'info', message: `Component has been sucessfully created.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not create component.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false)
            });
    }),

    updateDeviceComponent: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { componentClient } = injections;
        actions.setLoading(true);

        await componentClient.update(payload)
            .then(response => {
                actions.storeComponent({ mode: 'update', component: response });
                getStoreActions().audit.notify({ severity: 'info', message: `Component has been sucessfully updated.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not update component.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false)
            });
    }),

    deleteDeviceComponent: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { componentClient } = injections;
        actions.setLoading(true);

        await componentClient.delete(payload)
            .then(response => {
                actions.storeComponent({ mode: 'delete', componentId: payload.componentId });
                getStoreActions().audit.notify({ severity: 'info', message: `Component has been sucessfully deleted.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not delete component.`, payload: err })
            })
            .finally(() => {
                actions.setLoading(false)
            });
    }),

    // ** device file management
    uploadFile: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { deviceClient } = injections;

        await deviceClient.uploadFile(payload)
            .then(file => {
                actions.storeFile({ mode: 'insert', file: file });
                getStoreActions().audit.notify({ severity: 'info', message: `File has been sucessfully uploaded.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not uplaod file.`, payload: err });
            })
    }),

    downloadFile: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { deviceClient } = injections;

        await deviceClient.downloadFile(payload)
            .then(file => {
                // get data (extract file name, convert blob from base64 to utf8)
                const fname = file.fileName ?? 'sw_downloaded_file.unknown';
                const blob = base64toBlob(file.fileContent ?? '');

                // create download link
                let tempLink = document.createElement('a');
                tempLink.href = window.URL.createObjectURL(blob);
                tempLink.setAttribute('download', fname);
                tempLink.click();

                getStoreActions().audit.notify({ severity: 'info', message: `Downloading file has been initiated.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Download file error.`, payload: err });
            });

    }),

    deleteFile: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { deviceClient } = injections;

        return await deviceClient.deleteFile(payload)
            .then(() => {
                actions.storeFile({ mode: 'delete', fileId: payload.fileId });
                getStoreActions().audit.notify({ severity: 'info', message: `File has been sucessfully deleted.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not delete file.`, payload: err });
            });

    }),

    // ** cfg file management
    downloadCfgFile: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { deviceFileClient } = injections;

        await deviceFileClient.downloadFile(payload)
            .then(file => {
                // get data (extract file name, convert blob from base64 to utf8)
                const fname = file.fileName ?? 'sw_downloaded_file.unknown';
                const blob = base64toBlob(file.fileContent ?? '');

                // create download link
                let tempLink = document.createElement('a');
                tempLink.href = window.URL.createObjectURL(blob);
                tempLink.setAttribute('download', fname);
                tempLink.click();

                getStoreActions().audit.notify({ severity: 'info', message: `Downloading file has been initiated.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Download file error.`, payload: err });
            });
    }),

    deleteCfgFile: thunk(async (actions, payload, { injections, getStoreActions }) => {

        const { deviceClient } = injections;

        return await deviceFileClient.deleteFile(payload)
            .then(() => {
                actions.storeCfgFile({ mode: 'delete', fileId: payload.fileId });
                getStoreActions().audit.notify({ severity: 'info', message: `Configuration file has been sucessfully deleted.` })
            })
            .catch(err => {
                getStoreActions().audit.notify({ severity: 'error', message: `Can not delete configuration file.`, payload: err });
            });

    }),

    /* actions | device */

    storeDeviceDetail: action((store, payload) => {
        if (store.deviceDetail) {
            Object.assign(store.deviceDetail, payload.deviceDetail);
        } else {
            store.deviceDetail = payload.deviceDetail;
        }
    }),

    storeDeviceContact: action((store, payload) => {
        if (store.deviceDetail !== undefined) {
            if (store.deviceDetail.contact) {
                Object.assign(store.deviceDetail.contact, payload.contact);
            }
            else {
                store.deviceDetail.contact = payload.contact;
            }
        }
    }),

    storeMaintainers: action((store, payload) => {
        store.maintainers = payload.maintainers ?? [];
    }),

    /* actions | service op */

    storeServiceOperation: action((store, payload) => {
        if (store.deviceDetail !== undefined) {
            store.deviceDetail.serviceOperations = storeValue(
                store.deviceDetail.serviceOperations ?? [], 'id', {
                mode: payload.mode, id: payload.serviceOpId, item: payload.serviceOp
            });
        }
    }),

    storeServiceFile: action((store, payload) => {
        const serviceOp = store.deviceDetail?.serviceOperations?.find(service => service.id === payload.serviceOpId);
        if (serviceOp) {
            serviceOp.attachedFiles = storeValue(serviceOp.attachedFiles ?? [], 'id', {
                mode: payload.mode, id: payload.fileId, item: payload.file
            });
        }
    }),

    storeFile: action((store, payload) => {
        if (store.deviceDetail) {
            store.deviceDetail.attachedFiles = storeValue(store.deviceDetail.attachedFiles ?? [], 'id', { mode: payload.mode, id: payload.fileId, item: payload.file });
        }
    }),

    storeLicenseFile: action((store, payload) => {
        if (store.deviceDetail) {
            store.deviceDetail.licenseFiles = storeValue(store.deviceDetail.licenseFiles ?? [], 'id', { mode: payload.mode, id: payload.fileId, item: payload.file });
        }
    }),

    storeCfgFile: action((store, payload) => {
        if (store.deviceDetail) {
            store.deviceDetail.deviceFiles = storeValue(store.deviceDetail.deviceFiles ?? [], 'id', { mode: payload.mode, id: payload.fileId, item: payload.file });
        }
    }),

    // component  actions
    storeComponent: action((store, payload) => {
        if (store.deviceDetail) {
            store.deviceDetail.components = storeValue(store.deviceDetail.components ?? [], 'id', { mode: payload.mode, id: payload.componentId, item: payload.component });
        }
    }),

}

export default deviceModel;
